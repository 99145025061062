$mint: #00cec9;
$mintLeaf: #00b894;
$light: #f5f6fa;
$dark: #222D39;
$violet: #5f27cd;

// Gird system
$screen-xs: 480px;
$screen-xs-min: $screen-xs;
$screen-phone: $screen-xs-min;
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-tablet: $screen-sm-min;
$screen-md: 992px;
$screen-md-min: $screen-md;
$screen-desktop: $screen-md-min;
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
$screen-lg-desktop: $screen-lg-min;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-float-breakpoint: $screen-sm-min;
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);
$grid-tablet: (720px + $grid-gutter-width);
$grid-sm: $grid-tablet;
$grid-desktop: (940px + $grid-gutter-width);
$grid-md: $grid-desktop;
$grid-large-desktop: (1140px + $grid-gutter-width);
$grid-lg: $grid-large-desktop;