body {
    &.modal-docked {
        overflow-y: hidden;
        height: 100vh;
        padding-right: 15px;
    }
}

.cl-mint {
    color: $mint;
}
// Typo
.section-title {
    h2 {
        margin-bottom: 16px;
        text-transform: uppercase;
        color: $mint;
    }
}

.block-ellipsis{
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 50px;
    margin: 0 auto;
    font-size: 15px;
    line-height: 24px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 16px;
    margin-top: 6px;
}

// Padding & Margin
.section-box {
    padding-top: 40px;
    padding-bottom: 40px;

}
.project-boxes {
    margin-top: 16px;
    .media-body {
        width: 100%;
    }
}
.sc999-footer {
    a {
        color: $mint;
        &:hover {
            color: $mint;
        }
    }
    .copyright {
        span {
            @media screen and (max-width: $screen-xs-max) {
                display: block;
            }
        }
    }
    &.section-box {
        @media screen and (max-width: $screen-xs-max) {
            padding-top: 24px;
            padding-bottom: 24px;
        }
    }
}
.lines {
    border-top: 2px solid $mint;
    &::before, &:after{
        content: "";
        position: absolute;
        top: -2px;
        width: 30px;
        border-top: 2px solid $mint;
    }
    &::before {
        left: -34px;
    }
    &:after {
        right: -34px;
    }
}

// Section
.media {
    margin-bottom: 30px;
    .project-boxes &{
        -webkit-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        background: #fff;
        @media screen and (min-width: $screen-sm) {
            &:hover {
                background: #fff;
                box-shadow: 0 8px 48px 0 rgba($color: $mintLeaf, $alpha: 0.5)
            }
            border: 1px solid rgba($color: $mintLeaf, $alpha: 0.4);
            border-radius: 4px;
            padding: 16px;
        }
    }
    h5 {
        font-size: 18px;
        margin-top: 16px;
        color: $mint;
    }
}

// Button
.btn-view {
    background-color: $mint;
    color: $light;
    font-size: 16px;
    padding: 8px 16px;
    transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s;
    &:hover {
        background-color: $light;
        color: $mint;
        transition: all ease 0.5s;
        -webkit-transition: all ease 0.5s;
    }
}
// Images
.react-images__container {
    border: 1px dotted $mint;
    padding: 0 15px;
}
.react-images__footer__count {
    span {
        margin-left: 0;
        text-align: right;
    }
}
.react-images__navigation {
    button {
        background-color: $mint;
        transition: all ease .5s;        
        &:hover {
            svg {
                fill: $mint;
                transition: all ease .5s;
            }
        }
    }
}

.media-demo {
    @media screen and (max-width: $screen-md-max) {
        margin-top: 12px;
    }
}

.project-modal {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    .btn-close {
        position: absolute;
        top: 8px;
        left: auto;
        right: 15px;
        border: 1px solid $mint;
        border-radius: 5px;
        transition: all ease .5s;
        -webkit-transition: all ease .5s;
        min-width: 60px;
        svg {
            color: $mint;
            font-size: 24px;
            transition: all ease .5s;
            -webkit-transition: all ease .5s;
        }
        &:hover {
            background-color: $mint;
            transition: all ease .5s;
            -webkit-transition: all ease .5s;
            svg{
                color: $light;
            }
        }
    }
    .btn-exit {
        border: 1px solid $mint;
        border-radius: 5px;
        transition: all ease .5s;
        -webkit-transition: all ease .5s;
        min-width: 120px;
        font-size: 20px;
        display: block;
        margin: 15px auto 0;
        background-color: rgba($color: $mint, $alpha: 0.9);
        color: $light;
        &:hover {
            background-color: rgba($color: $mintLeaf, $alpha: 0.9);
            transition: all ease .5s;
            -webkit-transition: all ease .5s;
            color: $light;
        }
    }
}
.project-content {
    margin-top: 16px;
    h3, h5, b{
        color: $mint;
    }
}
.scrollup {
    background-color: $mint;
}
.media-body {
    position: relative;
    .framework{
        color: $violet;
        font-size: 18px;
        font-weight: bold;
        position: absolute;
        top: 12px;
        right: 4px;
    }
}

#login {
    height: calc(100vh - 107px);
}

.img-project {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding-bottom: 53%;
    width: 100%;
}

.center-mobile{
    @media screen and (max-width: 767px ) {
        text-align: center;
    }
}