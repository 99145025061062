.sc999 {
    &-navbar {
        padding-top: 8px;
        padding-bottom:8px;
        background-color: $mint;
    }
    &-logo{
        margin: 0;
        color: $light;
    }
}